import sampleImg from "./download.png";


export const songType = [
  { label: "Instrumental", value: "instrumental" },
  { label: "Sample", value: "sample" },
  { label: "Full Song", value: "full_song" }
];

export const rolesArr = [
  "Executive Producer",
  "Mixing Engineer",
  "Songwriter",
  "Music Producer",
  "Singer",
  "Composer",
];

export const collaboratorsArr = [
  {
    id: 1,
    name: "Raul Cardenas",
    imgSrc: sampleImg,
    email: "abc@gmail.com",
    roles: ["Executive Producer", "Mixing Engineer"],
  },
  {
    id: 2,
    name: "Joseph",
    imgSrc: sampleImg,
    email: "abc@gmail.com",
    roles: ["Songwriter", "Singer"],
  },
  {
    id: 3,
    name: "John smith",
    imgSrc: sampleImg,
    email: "abc@gmail.com",
    roles: ["Composer", "Music Producer"],
  },
];

export const attachedFilesTableData = [
  {
    id: 1,
    link: "",
    fileName: "Memories",
    artist: "SoundBoyz",
    uploadedDate: "2023-09-26T10:23:45.678Z",
    uploadedBy: { name: "User1", icon: sampleImg },
  },
  {
    id: 2,
    link: "",
    fileName: "Borken Memories",
    uploadedDate: "2024-09-26T10:23:45.678Z",
    artist: "SoundBoyz",
    uploadedBy: { name: "User1", icon: sampleImg },
  },
  {
    id: 3,
    link: "",
    fileName: "Borken Memories",
    uploadedDate: "2024-09-26T10:23:45.678Z",
    artist: "SoundBoyz",
    uploadedBy: { name: "User1", icon: sampleImg },
  },
  {
    id: 4,
    link: "",
    fileName: "Borken Memories",
    uploadedDate: "2024-09-26T10:23:45.678Z",
    artist: "SoundBoyz",
    uploadedBy: { name: "User1", icon: sampleImg },
  },
  {
    id: 5,
    link: "",
    fileName: "Borken Memories",
    uploadedDate: "2024-09-26T10:23:45.678Z",
    artist: "SoundBoyz",
    uploadedBy: { name: "User1", icon: sampleImg },
  },
  {
    id: 6,
    link: "",
    fileName: "Borken Memories",
    uploadedDate: "2024-09-26T10:23:45.678Z",
    artist: "SoundBoyz",
    uploadedBy: { name: "User1", icon: sampleImg },
  },
  {
    id: 7,
    link: "",
    fileName: "Borken Memories",
    uploadedDate: "2024-09-26T10:23:45.678Z",
    artist: "SoundBoyz",
    uploadedBy: { name: "User1", icon: sampleImg },
  },
  {
    id: 8,
    link: "",
    fileName: "Borken Memories",
    uploadedDate: "2024-09-26T10:23:45.678Z",
    artist: "SoundBoyz",
    uploadedBy: { name: "User1", icon: sampleImg },
  },
  {
    id: 9,
    link: "",
    fileName: "Borken Memories",
    uploadedDate: "2024-09-26T10:23:45.678Z",
    artist: "SoundBoyz",
    uploadedBy: { name: "User1", icon: sampleImg },
  },
  {
    id: 10,
    link: "",
    fileName: "Borken Memories",
    uploadedDate: "2024-09-26T10:23:45.678Z",
    artist: "SoundBoyz",
    uploadedBy: { name: "User1", icon: sampleImg },
  },
  {
    id: 11,
    link: "",
    fileName: "Borken Memories",
    uploadedDate: "2024-09-26T10:23:45.678Z",
    artist: "SoundBoyz",
    uploadedBy: { name: "User1", icon: sampleImg },
  },
  {
    id: 12,
    link: "",
    fileName: "Borken Memories",
    uploadedDate: "2024-09-26T10:23:45.678Z",
    artist: "SoundBoyz",
    uploadedBy: { name: "User1", icon: sampleImg },
  },
];
